import React from 'react';

import Button from './Button';
import AnimatedSection from './AnimatedSection';

import successImg from '../assets/images/success.png';

class Success extends React.Component {
  handleClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLScSapxj_dusjbaRr4z_QkTAOVHL0OWMJnYqk09tMO9v9kHrCg/viewform?usp=sf_link', '_blank');
  };

  render() {
    return (
      <AnimatedSection>
        <div className="success-section">
          <div className="success-section-image">
            <img src={successImg} alt="waitlist" />
          </div>
          <div className="success-section-middle-wrapper">
            <div className="success-section-middle">
              <div className="success-section-heading">
                Congratulations
              </div>
              <div className="success-section-subheading">
                You have successfully joined our waitlist.
              </div>
              <div className="success-section-button">
                <Button background="bright" size="large" arrow onClick={this.handleClick}>TAKE A QUICK SURVEY</Button>
              </div>
            </div>
          </div>
        </div>
      </AnimatedSection>
    );
  }
}
export default Success;
