import React from 'react';

import Button from './Button';
import Input from './Input';

import AnimatedSection from './AnimatedSection';
import waitlistImg from '../assets/images/waitlist.png';
import infoIcon from '../assets/images/info-icon.png';

class Waitlist extends React.Component {
  state = {
    disabled: true,
    invalidEmail: false,
  };

  handleInput = e => {
    if (e.target.value && !(/^[^@]+@[^@]+\.[^@]+$/.test(e.target.value))) {
      this.setState({
        disabled: true,
        invalidEmail: true,
      });
    } else {
      this.setState({
        disabled: false,
        invalidEmail: false,
      });
    }
  };

  handleSubmit = () => {
    const { handleSubmit } = this.props;
    handleSubmit();
  };

  render() {
    const { disabled, invalidEmail } = this.state;
    return (
      <AnimatedSection>
        <div className="waitlist-section">
          <div className="waitlist-section-image">
            <img src={waitlistImg} alt="waitlist" />
          </div>
          <div className="waitlist-section-right-wrapper">
            <div className="waitlist-section-right">
              <div className="waitlist-section-heading">
                Want early access to our other offers?
              </div>
              <div className="waitlist-section-subheading">
                Finance at every step. From application fees, health insurance, to visa
                processing, etc. You only get charged on what you use!
              </div>
              <div className="waitlist-section-email-button">
                <Input placeholder="Input Email" onChange={this.handleInput} type="email" autocomplete="email" name="email" />
                <Button disabled={disabled} background="dark" size="large" onClick={this.handleSubmit}>
                  {invalidEmail && (
                    <img src={infoIcon} alt="" style={{ margin: '0 2px 3px 0', width: '18px' }} />
                  )}
                  {invalidEmail ? 'INVALID EMAIL' : 'JOIN OUR WAITLIST'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </AnimatedSection>
    );
  }
}

export default Waitlist;
