import React from 'react';

import {
  Navbar,
  TopSection,
  Join,
  Waitlist,
  Success,
  Footer,
  Survey
} from '../../components';

class Home extends React.Component {
  state = {
    email: '',
    showSuccess: false,
  };

  handleInput = e => {
    this.setState({ email: e.target.value });
  };

  handleSubmit = () => {
    const { contact } = this.props;
    const { email } = this.state;

    if (email.length > 0 && email.length < 50) {
      const lowerCaseEmail = email.toLowerCase();
      setTimeout(() => {
        contact(
          lowerCaseEmail,
          'hybrid',
          () => {
            this.setState({ showSuccess: true });
            // setTimeout(() => {
            //   this.setState({ showSuccess: false });
            // }, 8000);
            setTimeout(() => {
              this.setState({ email: '' });
            }, 10000);
          },
          () => {}
        );
      }, 500);
    }
  };

  render() {
    const { email, showSuccess } = this.state;
    return (
      <div id="home">
        <Navbar />
        <div id="carouselExampleDark" className="carousel slide" data-bs-ride="carousel" data-bs-wrap="true">
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="5000">
              <TopSection
                heading="Get cash for your relocation"
                subheading="Do you need support with cash for your documents, visa processing fee or health insurance?"
                background="T1"
              />
            </div>

            <div className="carousel-item" data-bs-interval="5000">
              <TopSection
                heading="Get easy finance to move to the US"
                subheading="Hybrid helps you cover costs for your GRE, uni applications, visa processing, health insurance, work-based relocation and more!"
                background="T2"
              />
            </div>

            <div className="carousel-item" data-bs-interval="5000">
              <TopSection
                heading="Follow your dreams, on us."
                subheading="Hybrid is your partner as you move to the United Kingdom. If you're a Global talent, prospective student, or relocating for work."
                subheadingExtra="Join Hybrid and see how."
                background="T3"
              />
            </div>

            <div className="carousel-item" data-bs-interval="5000">
              <TopSection
                heading="Can't find what you're looking for?"
                subheading="We are always looking to improve."
                subheadingExtra="Please fill this 3 mins survey."
                background="T4"
                buttonText="TAKE OUR SURVEY"
                buttonLink="https://docs.google.com/forms/d/e/1FAIpQLScSapxj_dusjbaRr4z_QkTAOVHL0OWMJnYqk09tMO9v9kHrCg/viewform?usp=sf_link"
              />
            </div>

            {/* <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" />
              <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2" />
              <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3" />
              <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 3" />
            </div> */}
          </div>
        </div>

        <div className="middle-section">
          <Join />
          <Survey />
          {!showSuccess && <Waitlist handleInput={this.handleInput} handleSubmit={this.handleSubmit} value={email} />}
          {showSuccess && <Success key="2" />}
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
